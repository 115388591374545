.header {
  background-image: url("../../assets/images/Header_Desktop.webp");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 35px 250px;
}

.header h1 {
  /* margin-top: 97px; */
  margin-top: 35px;
}

.containerLogo img {
  width: 200px;
  height: auto;
}

.containerImage {
  text-align: center;
  margin-top: 45px;
}

.containerImage img {
  width: 420px;
  height: auto;
}
.text1 {
  text-align: left;
  font-size: 1.3em;
  font-weight: 400;
  margin-top: 54px;
}

.text2 {
  font-size: 1em;
  margin-top: 11px;
  font-weight: 700;
}

.header h2 {
  font-size: 1.8em;
}
.titleBlue {
  font-size: 2.2em;
  color: var(--text-color);
  text-align: center;
}

.buttonRed {
  border: none;
  outline: none;
  padding: 15px 30px;
  background-color: #fa4616;
  cursor: pointer;
  font-size: 1.2em;
  border-radius: 28px;
  margin-top: 20px;
  color: #fff;
  height: 58px;
  font-weight: 700;
  margin-top: 42px;
  border: 2px solid #fa4616;
  transition: all 0.3s;
}
.buttonRed:hover {
  color: #fa4616;
  background-color: #fff;
  border-color: #fa4616;
}
.buttonBlue {
  border: none;
  outline: none;
  padding: 15px 30px;
  background-color: #0067df;
  cursor: pointer;
  font-size: 1.2em;
  border-radius: 28px;
  margin-top: 20px;
  color: #fff;
  height: 58px;
  font-weight: 700;
  margin-top: 24px;
  border: 2px solid #0067df;
  transition: all 0.3s;
}
.buttonBlue:hover {
  color: #0067df;
  background-color: #fff;
  border-color: #0067df;
}
.section1 {
  padding: 88px 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2 !important;
}

/* .sinergia {
  display: flex;
  justify-content: center;
  align-items: center;
} */

.benefitsTitle {
  font-size: 2.7em;
  color: var(--primary-color);
  margin-top: 57px;
  text-align: center;
  margin-bottom: 63px;
}

.section2 {
  padding: 57px 250px;
  justify-content: center;
  align-items: center;
  background-color: #fff !important;
}

.grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 100%;
}

.grid-item {
  width: calc(33.33% - 10px);
  margin: 5px;
  background-color: #fff;
  padding: 20px;
  text-align: center;
  min-height: 200px;
}

.itemImage img {
  width: 100px;
  height: 100px;
  aspect-ratio: 1/1;
}

.itemText {
  margin-top: 20px;
}

.section3 {
  padding: 57px 250px;
  background: rgba(27, 130, 190, 0.1);
}

.section3 {
  padding: 120px 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.youtubeD img {
  width: 840px;
}
.youtubeDText {
  margin-top: 36px;
  font-size: 1.2em;
}
.hrYoutubeD {
  margin-top: 24px;
  height: 4px;
  background: #a6a6a6;
}
.section4 {
  padding: 120px 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #1b82be;
}

.titleSourIT {
  font-size: 2.5em;
  text-align: left;
  color: #fff;
}

.textSourIT {
  font-size: 1.2em;
  text-align: left;
  color: #fff;
  margin-top: 62px;
  font-weight: 400;
}

.hrSourIT {
  margin-top: 62px;
  height: 4px;
  background: #fff;
}

.textNumber {
  font-size: 2.25rem;
  color: #fff;
  margin-top: 40px;
}

.textNumber:nth-child(1) {
  margin-top: 0 !important;
}

.textNumberBig {
  font-size: 2.8em;
  color: #fff;
  line-height: 1;
}

.footerLogo {
  text-align: center;
  margin-top: 33px;
}

.footerLogo img {
  width: 220px;
  height: auto;
}
.socialMedia {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.socialMedia img {
  width: 38px;
  height: auto;
}

.footerLogo p {
  font-size: 1em;
}

.socialMedia {
  margin-top: 16px;
}

@media only screen and (max-width: 600px) {
}

/*Tablet*/
@media only screen and (max-width: 768px) {
  .header {
    background-image: url("../../assets/images/Header_Mobile.webp");
  }
  .youtubeD {
    text-align: center;
    margin-top: 70px;
  }
  .youtubeD img {
    width: 100%;
  }
  .header {
    padding: 20px;
  }
  .containerImage img {
    width: 235px;
  }
  .buttonRed,
  .buttonBlue {
    font-size: 1.1em;
    padding: 15px 15px;
  }
  .header h1 {
    margin-top: 30px;
    font-size: 3em;
  }

  .titleBlue {
    font-size: 1.8em;
  }
  .section1 {
    padding: 20px;
    margin-top: 26px;
  }
  .section2 {
    padding: 20px;
  }
  .section3 {
    padding: 20px;
  }
  .grid-item {
    width: calc(50% - 10px);
  }
  .benefitsTitle {
    font-size: 1em;
  }
  .section4 {
    padding: 20px;
  }
  .textNumber {
    font-size: 1.6rem;
    color: #fff;
    margin-top: 40px;
  }

  .textNumberBig {
    font-size: 2em;
    color: #fff;
    line-height: 1;
  }
  .textNumber:nth-child(1) {
    margin-top: 40px !important;
  }
}

/*Laptop*/
@media only screen and (min-width: 992px) and (max-width: 1440px) {
  .section1 {
    padding: 40px;
  }
  .section2 {
    padding: 40px;
  }
  .section3 {
    padding: 40px;
  }
  .header h1 {
    font-size: 3em;
  }
  .containerImage img {
    width: 335px;
  }
  .section4 {
    padding: 40px;
  }
}

/*Large Screen*/
@media only screen and (min-width: 1440px) {
}

/*Large Screen*/
@media only screen and (min-width: 1920px) {
}

.p-inputtext:enabled:focus {
  box-shadow: 0 0 0 0.2rem #f58060 !important;
  border-color: #fa4616 !important;
}

.borderForm {
  border: 4px solid #fff !important;
  border-radius: 10px;
  padding: 30px 40px !important;
}

.field {
  margin-top: 10px;
}

.modalBack {
  background-color: #fff;
}

.p-button {
  background: #fa4616 !important;
  border: 1px solid #fa4616 !important;
}
