@font-face {
  font-family: "HLTSR";
  font-style: normal;
  src: local("HelveticaLTRegular"),
    url("./assets/fonts/HelveticaLTRegular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "HLTSB";
  font-style: normal;
  src: local("HelveticaLTBold"),
    url("./assets/fonts/HelveticaLTBold.ttf") format("truetype");
  font-display: swap;
}
:root {
  --color-bg: #f5f5f5 !important;
  --primary-color: #58595b !important;
  --secondary-color: #ebebeb !important;
  --tertiary-color: #ffffff !important;
  --text-color: #1b82be !important;
  --text-color-secondary: #4d4d4d !important;
  --text-color-light: #808080;
  --gray-variant: #869093;
  --font-regular: "HLTSR";
  --font-bold: "HLTSB";
  --content-padding: 1.25rem !important;
  --inline-spacing: 1.8em !important;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  list-style: none;
  text-decoration: none;
}

body {
  background: var(--color-bg);
  font-family: var(--font-regular) !important;
  overflow-x: hidden !important;
}

/*Scrollbar*/
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: var(--color-bg) !important;
  border: none;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--secondary-color);
  border-radius: 10px;
}

.center {
  text-align: center;
}
.justify {
  text-align: justify;
}

.left {
  text-align: left;
}

h1 {
  font-family: var(--font-bold);
  color: var(--primary-color);
  font-size: 2.8rem;
}

h2 {
  font-family: var(--font-bold);
  color: var(--primary-color);
  font-size: 2.2rem;
  text-align: center;
}

h3 {
  font-family: var(--font-bold);
  color: var(--primary-color);
  font-size: 2rem;
  text-align: center;
}

p {
  font-family: var(--font-regular);
  color: var(--primary-color);
  font-size: 1.5rem;
  text-align: center;
}

.guias {
  border: solid 1px #000;
}

.mhidden {
  display: none !important;
}

/*Medias Query*/
/*Mobile*/
@media only screen and (max-width: 600px) {
  h1 {
    font-size: 1.9rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  p {
    font-size: 1.1rem;
  }
}

/*Tablet*/
@media only screen and (max-width: 768px) {
  .dhidden {
    display: none !important;
  }

  .mhidden {
    display: flex !important;
  }
}

/*Laptop*/
@media only screen and (min-width: 992px) {
}

/*Large Screen*/
@media only screen and (min-width: 1440px) {
}

/*Large Screen*/
@media only screen and (min-width: 1920px) {
}
